@font-face {
  font-family: "HKLight";
  src: local("HKGrotesk-Light"), url("./fonts/HKGrotesk-Light.ttf") format("truetype");
}

@font-face {
  font-family: "HKRegular";
  src: local("HKGrotesk-Regular"), url("./fonts/HKGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "HKBold";
  src: local("HKGrotesk-Bold"), url("./fonts/HKGrotesk-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Glacial";
  src: local("GlacialIndifference-Regular"), url("./fonts/GlacialIndifference-Regular.otf") format("opentype");
}

@font-face {
  font-family: "GlacialBold";
  src: local("GlacialIndifference-Bold"), url("./fonts/GlacialIndifference-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Hackney";
  src: local("Hackney"), url("./fonts/Hackney.ttf") format("truetype");
}

body {
  margin: 0;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: white;
  font-family: Glacial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
